import Logo from "assets/images/navbar/logo.svg";
import { Link } from "react-router-dom";
import styles from "./NavBar.module.scss";
import { useSelector } from "react-redux";
import MenuImg from "assets/images/navbar/menu.svg";

const NavBar = () => {
  const displayName = useSelector((state) => state.user.user?.name);

  return (
    <aside className={`flex-column ${styles["nav-container"]}`}>
      <Link to={"/upload"}>
        <img className="logo" alt="logo.svg" src={Logo} />
      </Link>
      <div className="flex-between flex-column">
        <Link class="nav" to="/upload">
          <img alt="catalogs.svg" src={MenuImg} />
          <p>Tags</p>
        </Link>
        <Link class="nav" to="/products">
          <img alt="catalogs.svg" src={MenuImg} />
          <p>Photos</p>
        </Link>
      </div>
      <Link to="/profile" className="text-centered">
        <div className="profile">
          <h5>{displayName?.split(" ").reduce((n, m) => n[0] + m[0])}</h5>
        </div>
        <span>Profile</span>
      </Link>
    </aside>
  );
};

export default NavBar;
