import { createSlice } from "@reduxjs/toolkit";

const photoSlice = createSlice({
  name: "PhotoReducer",
  initialState: { photo: null },
  reducers: {
    setPhoto: (state, action) => {
      // Update state immutably
      return { ...state, photo: action.payload };
    },
    resetPhoto: (state, action) => {
      return { photo: null };
    },
  },
});

export const { setPhoto, resetPhoto } = photoSlice.actions;
export default photoSlice.reducer;
