import React, { useEffect, useRef } from "react";
import styles from "./AlertBox.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { resetAlert } from "../../redux/Alert/AlertReducer";

export default function AlertBox() {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.alert.message);
  const alertRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (alertRef.current && !alertRef.current.contains(event.target)) {
        dispatch(resetAlert());
      }
    };
    setTimeout(() => dispatch(resetAlert()), 3000);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  return (
    <div className={styles.alertBox} ref={alertRef}>
      {typeof message === "string" ? (
        <div>
          <label>{message}</label>
        </div>
      ) : (
        message
      )}
    </div>
  );
}
