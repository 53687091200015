import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import HomeLayout from "components/Layout/HomeLayout";
import Layout from "components/Layout/Layout";
import { useSelector } from "react-redux";
import Loader from "components/Loader/Loader";
const GeneratePhotos = lazy(() => import("pages/ProductPhotos/Photos"));
const UploadPage = lazy(() => import("pages/ProductPhotos/Upload"));

const Upload = lazy(() => import("pages/Tags/Upload"));
const Profile = lazy(() => import("pages/Profile/Profile"));
const Home = lazy(() => import("pages/Home/Home"));
const GenerateTag = lazy(() => import("pages/Tags/Tags"));

const AppRoutes = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<Layout />}>
          {isLoggedIn && (
            <>
              <Route path="upload" element={<Upload />} />
              <Route path="generate" element={<GenerateTag />} />
              <Route path="profile" element={<Profile />} />
              <Route path="products">
                <Route index element={<UploadPage />} />
                <Route path="generate" element={<GeneratePhotos />} />
              </Route>
              <Route path="*" element={<Navigate to={"/upload"} replace />} />
            </>
          )}
        </Route>
        <Route element={<HomeLayout />}>
          {!isLoggedIn && (
            <>
              <Route path="" element={<Home />} />
              <Route path="*" element={<Navigate to={"/"} replace />} />
            </>
          )}
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
