import { createSlice } from "@reduxjs/toolkit";

const apiSlice = createSlice({
  name: "ApiReducer",
  initialState: { loader: false },
  reducers: {
    setLoader: (state, action) => {
      // Update state immutably
      return { ...state, loader: true };
    },
    resetLoader: (state, action) => {
      return { loader: false };
    },
  },
});

export const { setLoader, resetLoader } = apiSlice.actions;
export default apiSlice.reducer;
