import NavBar from "components/NavBar/NavBar";
import React from "react";
import styles from "./Layout.module.scss";
import { Outlet } from "react-router-dom";
import BgLeft from "assets/images/backgrounds/background-top-left.svg";
import BgRight from "assets/images/backgrounds/background-top-right.svg";
import { useSelector } from "react-redux";
import AlertBox from "components/AlertBox/AlertBox";
import Loader from "components/Loader/Loader";

const Layout = () => {
  const message = useSelector((state) => state.alert.message);
  const loader = useSelector((state) => state.api?.loader);

  return (
    <div className={styles.layout}>
      <img src={BgLeft} alt="background-top-left.svg" className="top-left" />
      <img src={BgRight} alt="background-top-right.svg" className="top-right" />
      <div className="outer-wrapper">
        <NavBar />
        <div className="content-wrap">
          <Outlet />
        </div>
      </div>
      {message && <AlertBox />}
      {loader && <Loader />}
    </div>
  );
};

export default Layout;
