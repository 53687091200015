import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./Routes";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getToken, getUserInfo } from "lib/services";
import { resetLoader, setLoader } from "./redux/Api/ApiReducer";
import { reset, setLogin, setUser } from "./redux/User/UserReducer";
import { setAlert } from "./redux/Alert/AlertReducer";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        dispatch(setLoader());
        if (!token) {
          const res = await getToken();
          localStorage.setItem("accessToken", res.data.token);
          dispatch(setLogin(res.data.token));
        }
        const user = await getUserInfo();
        dispatch(setUser(user.data.userInfo));
        dispatch(resetLoader());
      } catch (err) {
        console.error(err.message);
        if (err?.response?.data?.status === 401) {
          localStorage.removeItem("accessToken");
          dispatch(reset());
        } else
          dispatch(
            setAlert(`Error: ${err.response?.data?.error || err.message}`)
          );

        dispatch(resetLoader());
      }
    };
    fetchData();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
