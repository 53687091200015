import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
  name: "AlertReducer",
  initialState: { message: null },
  reducers: {
    setAlert: (state, action) => {
      // Update state immutably
      return { ...state, message: action.payload };
    },
    resetAlert: (state, action) => {
      return { message: null };
    },
  },
});

export const { setAlert, resetAlert } = alertSlice.actions;
export default alertSlice.reducer;
