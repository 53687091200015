import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "UserReducer",
  initialState: {
    isLoggedIn: !!localStorage.getItem("accessToken"),
    user: null,
    accessToken: localStorage.getItem("accessToken"),
  },
  reducers: {
    setLogin: (state, action) => {
      return { ...state, isLoggedIn: true, accessToken: action.payload };
    },
    setUser: (state, action) => {
      // Update state immutably
      return { ...state, user: action.payload };
    },
    reset: (state, action) => {
      return { isLoggedIn: false, user: null, accessToken: null };
    },
  },
});

export const { setUser, reset, setLogin } = userSlice.actions;
export default userSlice.reducer;
