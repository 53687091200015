import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "ModalReducer",
  initialState: { popup: false },
  reducers: {
    showModal: (state, action) => {
      return { ...state, popup: true };
    },
    closeModal: (state, action) => {
      return { popup: false };
    },
  },
});

export const { showModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
