import { combineReducers } from "@reduxjs/toolkit";
import UserReducer from "./User/UserReducer";
import AlertReducer from "./Alert/AlertReducer";
import PhotoReducer from "./Photos/PhotosReducer";
import ApiReducer from "./Api/ApiReducer";
import ModalReducer from "./Modal/ModalReducer";
import ProductReducer from "./Photos/ProductReducer";

const RootReducer = combineReducers({
  user: UserReducer,
  alert: AlertReducer,
  photo: PhotoReducer,
  api: ApiReducer,
  modal: ModalReducer,
  product: ProductReducer,
});

export default RootReducer;
