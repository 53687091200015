import styles from "./Layout.module.scss";
import { Outlet } from "react-router-dom";
import React from "react";
import BgLeft from "assets/images/backgrounds/background-top-left.svg";
import BgRight from "assets/images/backgrounds/background-top-right.svg";

const HomeLayout = () => {
  return (
    <div className={`${styles["home-layout"]} ${styles.layout}`}>
      <img src={BgLeft} alt="background-top-left.svg" className="top-left" />
      <img src={BgRight} alt="background-top-right.svg" className="top-right" />
      <Outlet />
    </div>
  );
};

export default HomeLayout;
