import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
  name: "ProductReducer",
  initialState: { photo: null },
  reducers: {
    setProductImage: (state, action) => {
      // Update state immutably
      return { ...state, photo: action.payload };
    },
    resetProductImage: (state, action) => {
      return { photo: null };
    },
  },
});

export const { setProductImage, resetProductImage } = productSlice.actions;
export default productSlice.reducer;
