import axios from "axios";
import axiosInstance from "./axiosInstance";

const apiBaseUrl = process.env.REACT_APP_API_URL;

const getToken = () =>
  axios.get(`${apiBaseUrl}/api/v1/auth/access-tokens`, {
    withCredentials: true,
  });
const logout = () =>
  axiosInstance.post(`/api/v1/auth/logout`, "", {
    withCredentials: true,
  });

const getUserInfo = () => axiosInstance.get(`/api/v1/auth/user-details`);

const getCategories = () => axiosInstance.get(`/api/v1/categories`);
const getTags = (payload) =>
  axiosInstance.post(`/api/v1/tags`, payload, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
const getPreviousTags = (pageNo, limit) =>
  axiosInstance.get(`/api/v1/tags?page=${pageNo}&limit=${limit}`);
const getTagsById = (tagId) => axiosInstance.get(`/api/v1/tags/${tagId}`);
const deleteTagsById = (tagId) => axiosInstance.delete(`/api/v1/tags/${tagId}`);

const sendContact = (payload) =>
  axiosInstance.post(`/api/v1/contacts`, payload);

const getPreviousPhotos = (page, limit) =>
  axiosInstance.get(`/api/v1/images/generate?page=${page}&limit=${limit}`);
const generateProductPhotos = (payload) =>
  axiosInstance.post(`/api/v1/images/generate`, payload, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
const getPhotosById = (id) =>
  axiosInstance.get(`/api/v1/images/generate/${id}`);

const downloadTags = (id) =>
  axiosInstance.get(`/api/v1/tags/${id}/download`, { responseType: "blob" });

export {
  downloadTags,
  getToken,
  logout,
  getUserInfo,
  getCategories,
  getTags,
  getPreviousTags,
  getTagsById,
  deleteTagsById,
  sendContact,
  getPreviousPhotos,
  generateProductPhotos,
  getPhotosById,
};
